.schedule-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  color: var(--primary-text-color);

  .schedule-search-filter {
    width: 225px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    font-weight: 400;
    height: 100%;
    max-height: 600px;

    .schedule-search {
      position: relative;
      height: 36px;
      width: 100%;

      .search-input {
        font-family: 'Poppins-Regular';
        font-size: 10px;
        width: 100%;
        height: 36px;
        border: 1px solid var(--secondary-text-color);
        border-radius: 5px;
        padding: 15px 35px 15px 15px;
        background-color: var(--background-3);

        color: var(--secondary-text-color);

        ::placeholder {
          color: var(--secondary-text-color);
        }
      }

      .search-icon {
        position: absolute;
        right: 10px;
        top: 13px;
        width: 10px;
        height: 10px;
      }
    }

    .schedule-filter {
      border: 1px solid var(--primary-text-color);
      border-radius: 5px;
      margin-top: 11px;
      flex: 1;
      padding: 10px 10px 10px 12px;

      .schedule-filter-title {
        font-size: 14px;
        font-weight: 500;
      }
      .schedule-filter-item {
        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          padding: 4px 0;

          input {
            :checked {
              background-color: rgba(0, 0, 0, 0);
              color: 'white';
            }
          }
        }
      }
    }
  }

  .schedule-wrapper-calendar {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .schedule-select-date-assignee {
      height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .schedule-select-date {
        background-color: var(--background-3);
        border-radius: 5px;

        .date-picker {
          width: 200px;
          color: var(--color-1);
          input {
            text-align: center;
            font-weight: 700;
            font-size: 13px;
            color: var(--color-1);
          }
        }
        .anticon {
          color: var(--color-1);
          width: 47px;
          font-weight: 800;
          font-size: 15px;
        }
      }

      .schedule-select-assignee {
        margin-left: 27px;
        width: 250px;
        border-radius: 5px;
        overflow: hidden;
        color: var(--color-1);
        .ant-select-selection-item {
          font-weight: 700;
          font-size: 14px;
          color: var(--color-1);
        }
      }
    }

    .schedule-calendar {
      border: 1px solid;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .current {
        background-color: rgba(153, 93, 83, 0.58);
      }

      .schedule-calendar-header {
        width: 100%;
        padding-left: 82px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 45px;
        border-bottom: 1px solid;
        font-weight: 600;
        font-size: 12px;

        .schedule-calendar-header-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
          height: 100%;
        }
      }
      .schedule-calendar-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .schedule-calendar-content-time-slot-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          min-height: 50px;
          border: 1px solid var(--primary-text-color);
          position: relative;
          padding-left: 82px;

          .time-slot {
            width: 82px;
            font-size: 8px;
            font-weight: 700;
            padding-left: 5px;
            height: 100%;
            position: absolute;
            left: 0;
            border-right: 1px solid var(--primary-text-color);
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .time-slot-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            height: 100%;
            min-height: 50px;
            padding: 5px;
            color: white;
            font-weight: 600;

            a {
              color: white;
            }

            .task-in-time-slot {
              display: flex;
              flex-direction: column;
              align-items: center;

              border-radius: 5px;
              padding: 5px;
              width: 103px;

              span {
                width: 100%;
                height: 10px;
                font-size: 7px;
                font-weight: 500;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .default-task {
              background-color: #7e7e7e;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;

    .schedule-search-filter {
      width: 100%;

      .schedule-filter {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .schedule-filter-title {
          grid-column: span 4;
        }

        .schedule-filter-item {
          label {
            justify-content: flex-end;
            flex-direction: row-reverse;
            gap: 8px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .schedule-wrapper-calendar {
      .schedule-calendar {
        width: calc(100vw - 24px);
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }

        .schedule-calendar-header {
          min-width: 990px;
        }
        .schedule-calendar-content {
          min-width: 990px;
        }
      }
    }
  }
}

.booking-contract-area {
  margin-top: 35px;
  width: 100%;
  color: var(--primary-text-color);

  .booking-contract-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 25px;
      font-weight: 700;
      margin-left: 5px;
    }
  }
  .booking-contract-wrapper {
    background-color: var(--background-1);

    .booking-contract-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--background-2);
      color: var(--table-text-color);
      border-radius: 5px;
      padding: 8px 24px;
      margin-top: 15px;

      .booking-contract-search-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          margin-right: 15px;
          font-size: 15px;
          font-weight: 700;
          cursor: pointer;
        }
        .select {
          text-decoration: underline;
        }
      }
      .booking-contract-search-action {
        display: flex;
        flex-direction: row;
        align-items: center;

        .booking-contract-select-status {
          min-width: 220px;
          font-size: 14px;
          font-weight: 600;
          border-radius: 5px;
          margin-right: 10px;
          padding: 0 10px;

          .ant-select-selection-item {
            font-size: 14px;
            font-weight: 600;
          }
        }
        .booking-contract-create-contract {
          font-size: 14px;
          font-weight: 600;
          border-radius: 5px;
          margin-right: 10px;
          padding: 0 10px;
          color: var(--color-1);
        }

        .booking-contract-search-input {
          position: relative;
          .search-input {
            font-family: 'Poppins-Regular';
            font-size: 14px;
            font-weight: 600;
            height: 32px;
            border-width: 0px;
            border-radius: 5px;
            padding: 15px 35px 15px 15px;
            width: 280px;
            color: black;
            ::placeholder {
              color: gray;
            }
          }

          .search-icon {
            position: absolute;
            right: 10px;
            top: 13px;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
  .booking-contract-table {
    color: var(--table-text-color);

    .booking-contract-table-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 10px;
      font-weight: 700;
    }
    .booking-contract-table-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-height: 30px;
      padding: 0 10px;
      cursor: pointer;

      &.row-separate {
        background-color: var(--background-2);
      }
      &:hover {
        opacity: 0.85;
      }

      .action {
        background-color: var(--background-3);
        height: 20px;
        width: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
          color: var(--secondary-text-color);
        }
      }

      .service-duration {
        padding: 5px 0;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .booking-contract-wrapper {
      .booking-contract-search {
        flex-direction: column;
        gap: 8px;

        .booking-contract-search-left {
          align-self: start;
        }
        .booking-contract-search-action {
          align-self: end;
        }
      }
    }
  }
}

.schedule-calendar-configuration {
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .configuration-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .configuration-title-text {
      font-size: 12px;
      font-weight: 600;
      color: var(--primary-text-color);
    }

    .configuration-title-line {
      flex: 1;
      border: 1px solid var(--secondary-text-color);
    }
  }

  .configuration-weekdays {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 500px;
    margin-top: 30px;

    .configuration-day {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-left: 1px solid var(--secondary-text-color);
      padding: 5px 10px;

      .configuration-day-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: '100%';
        .configuration-day-name {
          font-size: 14px;
          font-weight: 600;
          color: var(--primary-text-color);
          margin-right: 12px;
        }
        .ant-switch-checked {
          background-color: var(--primary-btn-color);
        }
      }

      .configuration-day-slots {
        display: flex;
        flex-direction: column;
        width: '100%';
        margin-top: 18px;

        .configuration-day-slots-label {
          font-size: 12px;
          font-weight: 600;
          color: var(--primary-text-color);
          margin-right: 12px;
        }

        .configuration-day-slot-row {
          display: flex;
          flex-direction: row;
          margin-top: 9px;
          color: var(--primary-text-color);
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 4px;

          input {
            width: 80px;
            color: black;
          }

          .configuration-day-slot-remove-icon {
            color: #dc3d21;
            margin-left: 16px;
          }
        }

        .configuration-day-slots-add {
          margin-top: 15px;
          .configuration-day-slots-add-icon {
            color: var(--primary-btn-color);
            background-color: var(--primary-btn-text-color);
            border-radius: 2px;
            overflow: hidden;
            width: 20px;
            height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
          .configuration-day-slots-add-label {
            font-size: 12px;
            font-weight: 600;
            color: var(--primary-text-color);
          }
        }

        .configuration-day-slot-per-session {
          font-size: 12px;
          font-weight: 600;
          color: var(--primary-text-color);
          margin-top: 20px;
        }

        .configuration-day-slot-per-session-select {
          width: 70px;
          height: 25px;
          background-color: var(--background-3);
          font-size: 10px;
          font-weight: 600;
          color: black;
          border-radius: 5px;
          overflow: hidden;
          margin-top: 10px;

          .ant-select-selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 25px;
            border: none;
          }
        }
      }
    }
  }

  .schedule-calendar-configuration-save {
    background-color: var(--primary-btn-color);
    width: 180px;
    height: 36px;
    margin-top: 30;
    margin-bottom: 30;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;

    color: var(--primary-btn-text-color);
    font-size: 14px;
    font-weight: 600;
  }
}

.schedule-config-selection {
  .ant-select-item-option-content {
    font-size: 10px;
  }
}

.config-cutoff {
  text-align: center;
  padding: 0 24px;

  &-header {
    margin-top: 24px;
    font-size: 24px;
    font-weight: bold;
  }
  &-content {
    font-weight: bold;
  }

  button {
    background-color: var(--primary-btn-color);
    border: none;
    color: var(--primary-btn-text-color);
    font-size: 11px;
    font-weight: 400;
    height: 30;
    border-radius: 5px;
    width: 250px;
  }

  &-inputs {
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    strong {
      font-weight: 700;
      font-size: 20px;
    }
  }

  &-input {
    display: flex;
    gap: 8px;
    align-items: flex-end;

    input {
      padding: 12px 24px;
      width: 120px;
      border-radius: 8px;
    }
  }
}
