.invoice-detail {
  background-color: var(--invoice-background);
  box-shadow: rgba(78, 78, 78, 0.5) 0px 3px 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  color: var(--primary-text-color);
  font-size: 12;
  font-weight: 500;
  margin-bottom: 20px;

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      &.flex-width {
        max-width: unset;
      }

      &.term {
        flex: 2;
        margin-top: 15px;

        .title {
          margin-bottom: 12px;
        }
        .term-content {
          background-color: var(--background-3);
          border-radius: 5px;
          padding: 10px;
          color: var(--secondary-text-color);
          min-height: 120px;
          max-height: 480px;
          overflow-y: scroll;
        }
      }
      &.money {
        flex: 1;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .total {
            width: 300px;

            .price-line {
              display: flex;
              flex-direction: row;
              width: 100%;

              .label {
                flex: 1;
              }
            }
            .separator {
              border: 1px solid var(--primary-text-color);
              margin-top: 4px;
            }
          }
        }
      }

      .label-input {
        label {
          margin-bottom: 6px;
        }

        .hidden {
          color: transparent;
        }

        .service-type,
        .code,
        .name,
        .paymentMethod,
        .paymentMethodPre {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 10px;

          width: 320px;
          height: 40px;
          background-color: var(--background-3);
          border-radius: 10px;
          color: var(--secondary-text-color);
        }

        .paymentTerm,
        .paymentTermValue {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 10px;

          width: 180px;
          height: 40px;
          background-color: var(--background-3);
          border-radius: 10px;
          color: var(--secondary-text-color);
        }

        .confirmPaymentButton {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 0 10px;

          width: 180px;
          height: 40px;
          background-color: var(--tertiary-btn-color);
          border-radius: 10px;
          color: var(--tertiary-btn-text-color);
          outline: none;
          border: none;
        }

        .link {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 40px;
          width: 320px;

          text-decoration: underline;
          cursor: pointer;
          color: var(--primary-btn-color);
        }
      }
      .paid-image {
        img {
          height: 60px;
          width: auto;
        }
      }

      .may-overflow {
        max-height: 300px;
        overflow-y: scroll;
      }
    }
  }

  .services {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--background-3);
    border-radius: 5px;
    color: var(--secondary-text-color);
    .row {
      margin-top: 20px;

      .column {
        margin-right: 10px;

        .item {
          flex: 1;
        }

        label {
          width: 100%;
          height: 30px;
          padding: 3px 0;
          color: black;
          font-size: 12px;
          font-weight: 600;
        }
        .value {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 10px;

          width: 100%;
          height: 40px;
          background-color: var(--input-in-white-bg);
          border-radius: 10px;
          color: black;
        }

        &.service-name {
          flex: 3;
          padding: 0 10px;
        }
        &.description,
        &.note {
          flex: 2;
          .value {
            height: 100%;
            padding: 10px;
            justify-content: flex-start;
            align-items: flex-start;
          }
        }
      }
    }

    @media screen and(max-width: 912px) {
      > .row {
        flex-direction: column;
      }

      .description,
      .note {
        padding: 0 10px;
        .value {
          min-height: 100px;
        }
      }
    }
  }

  .download-csv-btn {
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 12px;

    button {
      text-decoration: underline;
      background: inherit;
      border: none;
      outline: none;
      margin-top: 8px;
      padding: 0;
      a {
        color: var(--primary-text-color);
      }
    }
  }

  .payment-noti {
    background-color: var(--background-3);
    color: black;
    padding: 4px 6px;
    border-radius: 12px;
    align-self: flex-end;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: var(--invoice-background);
  margin-top: 5px;
  margin-bottom: 20px;

  .add-vo {
    background-color: var(--background-3);
    color: black;
    font-size: 12px;

    width: 160px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: 10px;
    margin-left: 15px;
    cursor: pointer;
  }

  .total-all {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: var(--primary-text-color);
    width: 400px;
    margin-right: 40px;

    .price-line {
      display: flex;
      flex-direction: row;
      width: 100%;

      .label {
        flex: 1;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .separator {
      border: 1px solid var(--primary-text-color);
      margin-top: 4px;
      width: 100%;
    }
  }
}

.vo-invoices {
  display: flex;
  flex-direction: column;
  width: 100%;

  .download-csv-btn {
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 12px;

    button {
      text-decoration: underline;
      background: inherit;
      border: none;
      outline: none;
      margin-top: 8px;
      padding: 0;
      a {
        color: var(--primary-text-color);
      }
    }
  }
}

.vo-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: var(--invoice-background);
  box-shadow: rgba(78, 78, 78, 0.5) 0px 3px 7px;
  padding: 20px;
  border-radius: 5px;
  color: var(--primary-text-color);
  font-size: 12px;

  &--pdf {
    width: 80vw;
  }

  .vo-detail-info {
    display: flex;
    flex-direction: column;
    flex: 1;

    .vo-task-info {
      display: flex;
      flex-direction: row;
      width: 100%;

      .label-value {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 10px;

        .label {
          font-weight: 700;
          margin-left: 5px;
        }
        .value {
          background-color: var(--background-4);
          color: var(--secondary-text-color);
          height: 30px;
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          &.time {
            width: 150px;
          }
        }
      }
    }

    .vo-services {
      margin-top: 20px;
      .vo-service-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: var(--background-3);
        color: black;
        min-height: 40px;
        align-items: center;
        border-top: 1px solid var(--color-2);

        &.header {
          background-color: var(--background-2);
          height: 30px;
          color: var(--primary-text-color);
        }
        div {
          padding: 5px;
        }
      }
    }
  }
  .vo-detail-money {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .payment-remove {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex: 1;

      .payment-area {
        display: flex;
        flex-direction: row;
        min-width: 300px;

        .paid-image {
          margin: 0 8px;
          img {
            height: 52px;
            width: auto;
          }
        }
      }

      .remove {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          cursor: pointer;

          background-color: var(--background-2);
          color: (--primary-btn-text-color);
          height: 40px;
          border-radius: 5px;
          .btn-text {
            margin-left: 10px;
          }
        }
      }
    }
    .money {
      margin-top: 20px;
      .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .total {
          width: 300px;

          .price-line {
            display: flex;
            flex-direction: row;
            width: 100%;

            .label {
              flex: 1;
            }
          }
          .separator {
            border: 1px solid var(--primary-text-color);
            margin-top: 4px;
          }
        }
      }
    }
  }

  .label-input {
    label {
      margin-bottom: 6px;
    }

    .hidden {
      color: transparent;
    }

    .paymentMethod {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px;

      width: 160px;
      height: 32px;
      background-color: var(--background-3);
      border-radius: 10px;
      color: var(--secondary-text-color);
      margin-right: 8px;
    }

    .paymentTerm,
    .paymentTermValue {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px;

      width: 180px;
      height: 40px;
      background-color: var(--background-3);
      border-radius: 10px;
      color: var(--secondary-text-color);
    }

    .confirmPaymentButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      width: 180px;
      height: 32px;
      background-color: var(--tertiary-btn-color);
      border-radius: 10px;
      color: var(--tertiary-btn-text-color);
      outline: none;
      border: none;
    }
  }
}

.vo-invoice-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 800px;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .services {
      display: flex;
      flex-direction: column;
      min-width: 550px;
      background-color: var(--background-3);
      border-radius: 10px;
      padding: 20px;
      padding-top: 5px;
      margin-top: 20px;

      box-shadow: rgba(78, 78, 78, 0.5) 0px 3px 7px;

      .service-title {
        font-weight: 700;
        font-size: 14px;
        color: black;
        margin-bottom: 15px;
      }

      .service-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        &.header {
          font-weight: 700;
          margin-bottom: 5px;
        }

        .service-item-content {
          display: flex;
          flex-direction: row;
          align-items: center;

          .service-item-index {
            margin-right: 10px;
            width: 20px;
          }

          .service-selection {
            width: 250px;
            border-radius: 5px;
            margin-right: 20px;
            overflow: hidden;

            .ant-select-selector {
              border-radius: 5px;
            }
          }

          .service-discount-type {
            margin-right: 8px;
          }

          input,
          div {
            &.service-quantity {
              margin-right: 10px;
              width: 100px;
            }
            &.service-amount {
              margin-right: 10px;
              width: 100px;
            }
          }
        }
      }

      .add-service {
        cursor: pointer;
        margin-top: 10px;
        background-color: var(--primary-btn-color);
        color: var(--primary-btn-text-color);
        width: 150px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 10px;

        span {
          margin-left: 10px;
        }
      }
    }

    .tasks {
      min-width: 250px;
      background-color: var(--background-3);
      border-radius: 10px;
      padding: 20px;
      padding-top: 5px;
      margin-top: 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .task-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        .task-title {
          font-weight: 700;
          font-size: 14px;
          color: black;
          margin-bottom: 15px;
        }

        .task-selection {
          width: 100%;
          margin-bottom: 10px;
        }

        .task-info-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;

          &.header {
            font-weight: 700;
          }
        }
      }

      .note {
        font-size: 10px;
        font-weight: 400;
        font-style: italic;
      }
    }
  }

  .money {
    flex: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--primary-text-color);

    .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .total {
        width: 300px;

        .price-line {
          display: flex;
          flex-direction: row;
          width: 100%;

          .label {
            flex: 1;
          }
        }
        .separator {
          border: 1px solid var(--primary-text-color);
          margin-top: 4px;
        }
      }
    }

    .save-btn {
      margin-top: 20px;
      background-color: var(--primary-btn-color);
      color: var(--primary-btn-text-color);
      width: 300px;
      height: 40px;
      font-size: 14px;
      font-weight: 600;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
  }
}
