.invoice-detail-pdf,
.quote-detail-pdf {
  background-color: var(--background-3);
  min-width: 1080px;
  padding: 12px;
  font-size: 24px;

  label {
    font-weight: bold;
    padding-right: 12px;
  }

  .general-infor {
    display: flex;

    > div {
      width: 25%;
    }
  }

  .services {
    width: 100%;

    .service {
      width: 100%;
      display: flex;
      border: 1px solid black;

      &:not(:last-child) {
        border-bottom: none;
      }

      .service-detail {
        width: 25%;

        > div {
          label {
            border-bottom: 1px solid black;
            display: block;
            padding: 8px;
          }

          .h-100 {
            min-height: 150px;
          }
        }

        &-field {
          padding: 8px;
        }
      }

      .service-detail:not(:last-child) {
        border-right: 1px solid black;
      }
    }
  }

  .summary {
    width: 50%;
    margin-left: auto;

    .summary-detail {
      display: flex;

      > div {
        width: 50%;
      }

      &--total {
        padding: 8px 0;
        color: red;
        border-top: 1px solid black;
        border-bottom: 3px double black;
        font-weight: bold;
      }
    }
  }

  .company-information {
    margin-bottom: 24px;

    .company-infor {
      width: 50%;
      display: flex;
      margin: 12px 0;

      &-detail {
        width: 50%;
      }

      img {
        height: 100px;
        width: auto;
      }
    }
  }

  .specific-infor {
    width: 50%;
    display: flex;
    margin: 24px 0;

    &-detail {
      width: 50%;
    }
  }

  .term {
    width: 75%;
  }
}

.contract-booking-detail-pdf,
.onetime-booking-detail-pdf {
  background-color: var(--background-3);
  min-width: 1080px;
  padding: 12px;

  label {
    font-weight: bold;
    padding-right: 12px;
  }

  .general-infor {
    margin-bottom: 24px;

    > div {
      display: flex;

      label {
        display: block;
        width: 240px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .company-information {
    margin-bottom: 24px;

    .company-infor {
      width: 50%;
      display: flex;
      margin: 12px 0;

      &-detail {
        width: 50%;
      }

      img {
        height: 100px;
        width: auto;
      }
    }
  }

  .services {
    width: 100%;

    .service-container {
      .service-index {
        margin-bottom: 12px;
        padding-bottom: 6px;
        border-bottom: 2px solid black;
      }

      .service {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
        border: 1px solid black;

        .service-detail {
          width: 25%;

          > div {
            label {
              border-bottom: 1px solid black;
              display: block;
              padding: 8px;
            }

            .h-100 {
              min-height: 150px;
            }
          }

          > div:first-child {
            border-bottom: 1px solid black;
          }

          .no-border {
            border-bottom: none !important;
          }

          &-field {
            padding: 8px;
          }
        }

        .service-detail:not(:last-child) {
          border-right: 1px solid black;
        }
      }
    }
  }

  .appoinment {
    &-label,
    &-service-label {
      display: block;
      margin-bottom: 12px;
      padding-bottom: 6px;
      border-bottom: 2px solid black;
    }
  }

  .task {
    margin-bottom: 32px;

    .task-infor {
      display: flex;

      label {
        display: block;
        width: 200px;
      }
    }
  }
}

.invoice-detail-pdf-ver2,
.quote-detail-pdf-ver2,
.vo-detail-pdf {
  font-family: 'Times New Roman';
  min-width: 1080px;
  padding: 0 18px;
  font-size: 22px;
  background: #fff;

  .header {
    .invoice-header {
      font-size: 56px;
    }
  }

  .company-logo {
    height: 180px;
    width: auto;
  }

  .general-information {
    display: flex;
    gap: 32px;
    margin-bottom: 60px;

    label {
      font-weight: bold;
    }

    .header {
      margin-right: auto;
    }

    .address {
      padding: 0 12px;
      width: 30%;
    }
  }

  .services {
    width: 100%;
    text-align: right;

    tr {
      display: grid;
      grid-template-columns: 424px repeat(4, 1fr) 200px;
    }

    th:first-child,
    td:first-child {
      text-align: left;
    }

    &-header {
      border-bottom: 1px solid black;

      th {
        height: 50px;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid lightgray;
      }

      td {
        padding: 12px 0;
      }
    }
  }

  .total {
    padding-top: 24px;
    text-align: right;
    margin-bottom: 96px;

    &-item {
      margin-left: auto;
      width: 40%;
      justify-content: flex-end;
      display: flex;
      gap: 56px;
      &-discount {
        width: 80%;
      }

      p:nth-child(2) {
        width: 150px;
        text-align: right;
      }

      &.last {
        padding-top: 12px;
        font-weight: bold;
        border-top: 1px solid black;
      }
    }
  }

  .term {
    width: 80%;
    margin-bottom: 48px;

    .due-date {
      font-weight: bold;
    }
  }

  .specific-infor {
    width: 75%;
    display: flex;
    margin: 24px 0;

    label {
      font-weight: bold;
    }

    &-detail {
      width: 50%;
    }
  }

  .footer-pdf {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 48px;
    padding-top: 36px;
    border-top: 2px dashed black;

    &-icon {
      height: 24px;
      width: auto;
      background: inherit;
      position: absolute;
      top: -12px;
      left: 12px;
    }

    &-header {
      font-size: 56px;
    }

    &-company {
      margin-left: 36px;
      position: relative;

      &::after {
        position: absolute;
        content: 'To:';
        top: 0;
        left: -48px;
      }
    }

    &-sumup {
      &-item {
        display: grid;
        grid-template-columns: 1fr 2fr;

        label {
          font-weight: bold;
        }

        .sign {
          position: relative;
          border-bottom: 1px solid black;

          &::after {
            position: absolute;
            content: 'Enter the amount you are paying above';
            right: 0;
            bottom: -48px;
          }
        }
      }

      &-item.break-line {
        border-bottom: 1px solid lightgrey;
      }
    }
  }
}
