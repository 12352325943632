.app-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .app-modal-main {
    min-width: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: auto;
    padding: 10px;
    background-color: var(--modal-background);
    print-color-adjust: exact;
    max-width: 100vw;

    .app-modal-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 5px;
      border-bottom: 1px solid var(--modal-header-color);

      .app-modal-header-title {
        font-size: 20px;
        font-family: 'Poppins-Bold';
        color: var(--modal-header-color);
      }

      .app-modal-header-close {
        cursor: pointer;
        .anticon.anticon-close-circle {
          svg {
            width: 25px;
            height: 25px;
            color: var(--modal-header-close-color);
          }
        }
      }
    }

    .app-modal-content {
      max-width: 100%;
      overflow-x: auto;
    }
  }
}

.confirm-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .confirm-modal-main {
    position: relative;
    min-width: 510px;
    min-height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    overflow-y: auto;

    background-color: var(--background-3);
    padding: 50px;

    .confirm-modal-message {
      font-family: 'Poppins-SemiBold';
      font-size: 18px;
      color: var(--secondary-text-color);
      text-align: center;
    }

    .confirm-modal-des {
      font-family: 'Poppins';
      font-size: 18px;
      color: var(--secondary-text-color);
      text-align: center;
      margin-top: 30px;
    }

    .confirm-modal-btn {
      margin-top: 30px;

      .ok {
        width: 150px;
        height: 50px;
        color: var(--tertiary-btn-text-color);
        border-radius: 10px;
        background-color: var(--tertiary-btn-color);
        border: none;
        margin-right: 20px;
        font-family: 'Poppins-Medium';
        font-size: 15px;
      }

      .cancel {
        width: 150px;
        height: 50px;
        color: var(--secondary-text-color);
        border: 1px solid var(--secondary-text-color);
        border-radius: 10px;
        font-family: 'Poppins-Medium';
        font-size: 15px;
      }
    }

    .confirm-modal-close {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    @media screen and(max-width: 1024px) {
      width: 94vw;
    }
  }
}
