.left-nav {
  margin: 25px 24px 4px 4px;
  transition: all 0.5s;

  .left-nav-toggle {
    display: none;
  }

  .left-nav-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    gap: 4px;

    .left-nav-user-avatar {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      overflow: hidden;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .left-nav-user-noti {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .left-nav-menu {
    width: 76px;
    border-radius: 10px;
    border: 1px solid var(--background-3);
    background-color: var(--background-1);
    box-shadow: rgba(78, 78, 78, 0.5) 0px 3px 7px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 7px 0 64px 0;

    .left-nav-icon {
      margin-bottom: 25px;
      width: 70px;
      height: 44px;
    }

    .left-nav-menu-item {
      position: relative;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;

      &.selected {
        background-color: var(--background-3);
        box-shadow: #e8ebf4 0px 0px 15px;
        border-radius: 5px;
      }

      .tooltip {
        position: absolute;
        top: 50%;
        left: 125%;
        width: max-content;
        transform: translateY(-50%);
        background: var(--tooltip-background);
        border: 0.5px solid #fff;
        padding: 4px 6px;
        border-radius: 4px;
        transition: 0.5s;
        display: none;
        z-index: 9999;
        color: var(--tooltip-color);

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0px;
          width: 8px;
          height: 8px;
          background: var(--tooltip-background);
          z-index: 10000;
          transform: translate(-50%, -50%) rotate(45deg);
          border-bottom: 0.5px solid #fff;
          border-left: 0.5px solid #fff;
        }
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 36px;
    left: -86px;
    z-index: 999;

    &.open {
      left: 0px;
    }

    .left-nav-toggle {
      display: block;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 36px;
      height: 36px;
      z-index: 999;
      background: var(--background-1);
      border-radius: 4px;

      img {
        height: 100%;
        width: 100%;
        color: #fff;
      }
    }

    .left-nav-toggle.show {
      display: none;
    }

    .left-nav-user {
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
    }

    .left-nav-menu {
      padding-top: 72px;
    }
  }
}
