.notification-button-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px 0 18px 0;

  .priority-button {
    width: 138px;
    height: 35px;
    border-radius: 8px;
    border: 1px solid var(--secondary-text-color);
    font-size: 12px;
    font-family: 'Poppins-Medium';

    &.high {
      background-color: var(--background-3);
      margin-right: 20px;
      color: black;
      position: relative;

      ::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: var(--high-priority-color);
        border-radius: 2px;
        top: 9px;
        left: 10px;
      }

      &.active {
        background-color: var(--high-priority-color);
        margin-right: 20px;
        color: white;
        position: relative;

        ::before {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          background-color: var(--background-3);
          border-radius: 2px;
          top: 9px;
          left: 10px;
        }
      }
    }

    &.low {
      background-color: var(--background-3);
      margin-right: 20px;
      color: black;
      position: relative;

      ::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: var(--low-priority-color);
        border-radius: 2px;
        top: 9px;
        left: 10px;
      }

      &.active {
        background-color: var(--low-priority-color);
        margin-right: 20px;
        color: white;
        position: relative;

        ::before {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          background-color: var(--background-3);
          border-radius: 2px;
          top: 9px;
          left: 10px;
        }
      }
    }
  }
}

.notification-button-group button:last-child {
  font-size: 12px;
  font-family: 'Poppins-Medium';
  margin-left: auto;
  margin-right: 4px;
  background-color: var(--high-priority-color);
  border: none;
  color: var(--primary-btn-text-color);
  border-radius: 5px;
  width: 100px;
  height: 35px;
}

.notification-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 25px;
  max-height: 555px;
  overflow-y: auto;

  .reminder-panel-container {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 25px;

    .header {
      padding: 8px 0px 8px 20px;
      font-family: 'Poppins-Medium';
      font-family: 20px;
      color: white;
      background-color: var(--background-1);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .body {
      background-color: var(--background-2);
      min-height: 174px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      gap: 25px;
      display: flex;
      flex-direction: column;
      padding: 32px 48px;
    }
  }
}

.reminder-lalel-content {
  min-height: 110px;
  background-color: var(--background-3);
  border-radius: 7px;
  display: flex;
  padding: 30px;
  cursor: pointer;
  position: relative;

  &.low {
    border-left: 9px solid var(--low-priority-color);
  }

  &.high {
    border-left: 9px solid var(--high-priority-color);
  }

  .time {
    min-width: 150px;
    display: flex;
    justify-content: center;
    font-family: 'Bebas Neue';
    font-size: 18px;
  }

  .reminder {
    flex-grow: 1;
    margin-top: -8px;

    .name {
      font-family: 'Poppins-SemiBold';
      font-size: 20px;
      margin-bottom: 8px;
    }

    .description {
      font-family: 'Poppins-Medium';
      font-size: 15px;
    }
  }

  .button-detail {
    min-width: 80px;
    place-self: center;
    text-align: right;
    cursor: pointer;
  }

  .custom-badge {
    position: absolute;
    right: -10px;
    top: -10px;
    .ant-badge-status-dot {
      width: 15px;
      height: 15px;
    }
  }
}
