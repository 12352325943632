.schedule-legend-config {
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 57px;
  font-size: 10px;
  font-weight: 400;

  .schedule-legend {
    .legend {
      border: 1px solid rgba(0, 0, 0, 0);
      border-radius: 5px;
      height: 15px;
      padding: 0 8px;
      margin-left: 5px;
      overflow: hidden;
      color: white;
    }
  }
  .schedule-config {
    margin-top: 4px;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    gap: 16px;
  }
}
