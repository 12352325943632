.finance-tab-area {
  width: 100%;

  .finance-tab-left {
    display: flex;
    flex-direction: column;

    .finance-tab-main,
    .finance-tab-second {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      span {
        color: var(--primary-text-color);
        font-size: 12px;
        font-weight: 600;
        margin-right: 25px;
        cursor: pointer;
      }

      .selected {
        text-decoration: underline;
      }
    }
  }

  .finance-tab-action {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 8px 0;
    flex-wrap: wrap;
    gap: 8px;

    &--start {
      align-items: start;
    }

    button {
      background-color: var(--primary-btn-color);
      border: none;
      color: var(--primary-btn-text-color);
      font-size: 11px;
      font-weight: 400;
      height: 30;
      border-radius: 5px;
      margin-left: 10px;
    }

    .ant-select,
    .ant-picker {
      margin: 0 5px;
      min-width: 200px;
    }

    .ant-input-number {
      margin: 0 5px;
      min-width: 100px;
    }

    .amount-range {
      color: var(--primary-text-color);
    }
  }

  .export-csv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      background: inherit;
      text-decoration: underline;
      outline: none;
      border: none;
      color: var(--primary-text-color);
    }
  }

  @media screen and(max-width: 1024px) {
    .customer-page-list.finance {
      width: calc(100vw - 30px);
      overflow-x: scroll;

      .list {
        min-width: 998px;
      }
    }
  }
}

.quote-code {
  text-decoration: underline;
  cursor: pointer;
}

.term-gst-area {
  width: 100%;
  display: flex;
  flex-direction: column;

  .term-gst-tab {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 0 10px;

    span {
      color: var(--primary-text-color);
      font-weight: 600;
      font-size: 12px;
      margin-right: 25px;
    }

    .selected {
      text-decoration: underline;
    }
  }

  .term-gst-content {
    background-color: var(--background-2);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .ant-input-number {
      &-handler-wrap {
        display: none;
      }
    }

    span {
      color: var(--primary-text-color);
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    textArea {
      border-radius: 5px;
      min-height: 300px;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      background-color: var(--primary-btn-color);
      width: 250px;
      height: 40px;
      margin-top: 15px;
      border: none;
      border-radius: 5px;

      span {
        color: var(--primary-btn-text-color);
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}

.report-content {
  width: 100%;
  display: flex;
  flex-direction: row;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    border-radius: 10px;

    .chart-area {
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      background-color: var(--finance-background);
      border-radius: 10px;

      .chart-header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .title {
          color: var(--primary-text-color);
          font-size: 18px;
          font-weight: 700;
        }

        .chart-header-time-selection {
          display: flex;
          flex-direction: column;

          .frequency-content {
            display: flex;
            flex-direction: row;
            align-items: center;

            background-color: var(--background-3);
            border-radius: 16px;
            border: 1px solid var(--color-1);
            height: 40px;
            width: 200px;
            padding: 3px 6px;

            .frequency-item {
              flex: 1;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              height: 100%;
              cursor: pointer;

              span {
                font-size: 10px;
                font-weight: 700;
                color: var(--secondary-text-color);
              }

              &.selected {
                background-color: var(--background-1);
                border-radius: 16px;

                span {
                  color: var(--background-3);
                }
              }
            }
          }
        }

        .ant-picker {
          margin-top: 5px;
        }
      }

      .chart-content {
        margin-top: 20px;
        min-height: 560px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .ant-chart {
          flex: 1;
        }
      }
    }
  }

  .right {
    height: 100%;
    width: 300px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;

    overflow: hidden;

    .chart-donut {
      background-color: var(--finance-background);
      border-radius: 10px;
      padding: 10px 5px;
      margin-bottom: 10px;

      .chart-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .title {
          color: var(--primary-text-color);
          font-size: 18px;
          font-weight: 700;
          width: 100%;
        }

        .chart-header-time-selection {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: flex-end;

          .frequency-content {
            display: flex;
            flex-direction: row;
            align-items: center;

            background-color: var(--background-3);
            border-radius: 16px;
            border: 1px solid var(--color-1);
            height: 40px;
            width: 200px;
            padding: 3px 6px;

            .frequency-item {
              flex: 1;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              height: 100%;
              cursor: pointer;

              span {
                font-size: 10px;
                font-weight: 700;
                color: var(--secondary-text-color);
              }

              &.selected {
                background-color: var(--background-1);
                border-radius: 16px;

                span {
                  color: var(--background-3);
                }
              }
            }
          }
        }

        .ant-picker {
          margin-top: 10px;
        }
      }

      .chart-content {
        margin-top: 20px;
      }

      .extra {
        .extra-button {
          cursor: pointer;
          background-color: var(--primary-btn-color);
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          padding: 5px 10px;
          color: var(--primary-btn-text-color);
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .task,
    .customer {
      border-radius: 5px;
      padding: 10px 5px;
      background-color: var(--finance-background);
      margin-bottom: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;

      .up {
        color: var(--up);
      }

      .down {
        color: var(--down);
      }

      .report-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 100%;
        flex: 1;
        color: var(--primary-text-color);
        margin-left: 10px;

        .up-down-icon {
          width: 22px;
          height: 22px;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          &.up {
            background-color: var(--up-alpha-2);
          }

          &.down {
            background-color: var(--down-alpha-20);
          }

          .arrow-up {
            color: var(--up);
          }
        }

        .current-value {
          font-size: 24px;
          font-weight: 700;
          margin-left: 10px;
        }
      }

      .report-item-info {
        font-size: 10px;
        font-weight: 600;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .report-item-name {
          color: var(--primary-text-color);
          font-size: 14px;
          font-weight: 700;
        }

        .report-item-description {
          color: var(--primary-text-color);
          font-size: 8px;
          font-weight: 500;
        }
      }

      .report-item-service-detail {
        width: 100%;
        height: 75px;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.up {
          background-color: var(--up);
        }

        &.down {
          background-color: var(--down);
        }

        .service-name {
          color: var(--primary-text-color);
          font-weight: 700;
          font-size: 20px;
          text-align: center;
        }
      }
    }

    .service {
      border-radius: 5px;
      background-color: var(--finance-background);
      margin-bottom: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;

      .report-item-percent {
        font-size: 24px;
        font-weight: 700;
        color: var(--primary-text-color);
      }

      .report-item-info {
        font-size: 10px;
        font-weight: 600;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .report-item-name {
          color: var(--primary-text-color);
          font-size: 14px;
          font-weight: 700;
        }

        .report-item-description {
          color: var(--primary-text-color);
          font-size: 8px;
          font-weight: 500;
        }
      }

      .report-item-service-detail {
        width: 100%;
        height: 75px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.up {
          background-color: var(--up);
        }

        &.down {
          background-color: var(--down);
        }

        .service-name {
          color: var(--primary-text-color);
          font-weight: 700;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}

.report-cash-collection {
  display: flex;
  flex-direction: column;
  min-width: 1100px;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 10px 20px;
  }

  .cash-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: var(--background-1);
    overflow: hidden;

    .cash-row-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: var(--background-1);

      &:nth-child(2n) {
        background-color: var(--background-2);
      }

      .cash-row-detail {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        background-color: var(--background-3);

        .title {
          width: 100%;
          color: var(--secondary-text-color);
          font-weight: 700;
          font-size: 19px;
        }

        .list-detail {
          width: 920px;

          display: flex;
          flex-direction: column;
          border-radius: 10px;
          background-color: var(--background-1);
          overflow: hidden;

          .row-detail {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: 50px;

            &:nth-child(2n + 1) {
              background-color: var(--background-2);
            }

            .row-detail-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              color: white;
              justify-content: flex-start;
              padding: 0 5px 0 10px;

              &.header {
                font-weight: 700;
              }

              &.right {
                justify-content: flex-end;
                padding-right: 20px;
              }

              &.center {
                justify-content: center;
                padding-right: 20px;
              }
            }
          }

          .line-separate {
            width: 100%;
            border: 1px solid var(--secondary-text-color);
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .total {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            color: var(--secondary-text-color);
            font-weight: 700;
            padding-right: 20px;

            label {
              margin-right: 20px;
            }
          }
        }

        .total-area {
          width: 920px;
          margin-top: 10px;

          .line-separate {
            width: 100%;
            border: 1px solid var(--secondary-text-color);
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .total {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            color: var(--secondary-text-color);
            font-weight: 700;
            padding-right: 20px;

            label {
              margin-right: 20px;
            }

            span {
              font-size: 20px;
            }
          }
        }
      }
    }

    .cash-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-height: 50px;

      &.expanded {
        background-color: var(--background-2);

        .row-item {
          color: white;

          .btn {
            color: var(--primary-btn-color);
            background-color: var(--primary-btn-text-color-color);
          }
        }
      }

      .row-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        justify-content: flex-start;

        padding: 0 5px 0 10px;

        .btn {
          background-color: var(--secondary-btn-color);

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 25px;
          color: var(--secondary-btn-text-color);
          font-size: 12px;
          font-weight: 700;
          border-radius: 10px;
        }

        &.header {
          font-weight: 700;
        }

        &.right {
          justify-content: flex-end;
        }

        &.center {
          justify-content: center;
        }
      }
    }
  }
}

.config-timeframe {
  text-align: center;
  padding: 0 24px;

  &-header {
    margin-top: 24px;
    font-size: 24px;
    font-weight: bold;
  }

  button {
    background-color: var(--primary-btn-color);
    border: none;
    color: var(--primary-btn-text-color);
    font-size: 11px;
    font-weight: 400;
    height: 30;
    border-radius: 5px;
    width: 250px;
  }

  &-inputs {
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  &-input {
    display: flex;
    gap: 8px;
    align-items: flex-end;

    input {
      padding: 12px 24px;
      width: 120px;
      border-radius: 8px;
    }
  }
}
