.forgot-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .forgot-password-page-header {
    height: 250px;
    width: 100%;
    background-color: var(--background-3);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .title {
      font-size: 20px;
      font-family: 'Poppins-Medium';
    }

    .description {
      width: 350px;
      font-size: 14px;
      font-family: 'Poppins-Regular';
      text-align: center;
      line-height: 24px;
    }
  }

  .forgot-password-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 53px;

    .input-password-area {
      position: relative;

      .input-password-show-btn {
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 40px;
        width: 27px;
        height: 20px;

        &.display {
          top: 44px;
          right: 25px;
          width: 20px;
          height: 11px;
        }
      }
    }

    .input-value {
      width: 396px;
      margin-top: 20px;
      padding: 18px 2px 18px 34px;

      ::placeholder {
        color: var(--secondary-text-color);
      }
    }

    button {
      background-color: var(--primary-btn-color);
      color: var(--primary-btn-text-color);
      height: 50px;
      width: 330px;
      border: none;
      border-radius: 10px;
      margin-top: 37px;
      font-size: 16px;
      font-family: 'Poppins-Medium';
    }
  }

  &.success {
    width: 100%;
    height: 100%;
    background-color: var(--background-2);

    .forgot-password-page-header {
      background: none;
      color: white;

      .title {
        font-size: 26px;
        font-family: 'Poppins-Bold';
      }

      .description {
        width: 320px;
        font-family: 'Poppins-Medium';
      }
    }

    button {
      background-color: var(--primary-btn-color);
      color: var(--primary-btn-text-color);
      height: 54px;
      width: 214px;
      border: none;
      border-radius: 10px;
      margin-top: 25px;
      font-size: 16px;
      font-family: 'Poppins-Medium';
    }
  }
}
